.lato-font {
    font-family: 'Lato', sans-serif;
}

.roboto-mono-font {
    font-family: 'Roboto Mono', monospace;
}

.contact {
    display:flex;
    justify-content: center;
    align-items: center;
}

form {
    max-width: 600px;
    width: 100%;
    padding: 25px 25px 30px;
    border-radius: 8px;
    box-sizing: border-box;
}

form h2 {
    font-size: 30px;
    text-align: center;
}


form .input-box {
    margin-top: 20px;

}

.input-box .field {
    width: 100%;
    height: 15px;
    background: transparent;
    padding: 20px;
    font-size: calc(10px + 0.390625vw);
    margin-top: 16px;
    display: flex;
    box-sizing: border-box;
}

.message {
    width: 100%;
    height: 150px;
    min-height: 100px;
    max-height: 600px;
    resize: vertical;
    background: transparent;
    padding: 15px;
    font-size: 16px;
    margin-top: 16px;
    box-sizing: border-box;
}

form button {
    font-size: 15px;
    margin-top: 15px;
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    background-color: #243746;
    font-size: calc(10px + 0.390625vw);
}

.name-fields {
    display: flex;
    gap: 20px;
}

.name-fields .field {
    flex: 1; 
    height: 15px;
    background: transparent;
    padding: 15px;
    font-size: calc(10px + 0.390625vw);
    margin-top: 16px;
    box-sizing: border-box;
}

.name-box {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 40px; 
    margin-top: 20px;
}

.name-box .field {
    width: 100%; 
    height: 15px;
    background: transparent;
    padding: 20px;
    font-size: calc(10px + 0.390625vw);
    margin-top: 16px;
}

button label {
    font-size: calc(10px + 0.390625vw);
}