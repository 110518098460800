.lato-font {
    font-family: 'Lato', sans-serif;
}

.roboto-mono-font {
    font-family: 'Roboto Mono', monospace;
}

.form-background {
    background-color: #e1edf7;
    padding: 20px;
}

.team {
    text-align: center;
    display: grid;
    max-width: 2000px;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 2% 10% 0%;
}

.contact-title {
    text-align: center;
    font-size: calc(30px + 0.390625vw);
    background-color: #243746;
    color: white;
    margin-bottom: 0;
    margin-top: 0;
    padding: 20px;
}

.contact-center {
    text-align: center;
    padding: 10px 0;
}

.profile-img {
    border-radius: 50%;
    min-width: 15%;
    max-width: 35%;
}

.job-title {
    margin-top: 0;
}

.employee-name {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .team {
        grid-template-columns: 1fr;
        padding: 0 5%;
    }

    .profile-img {
        width: 40%;
    }

    .contact-title {
        font-size: calc(20px + 1vw);
        padding: 15px;
    }

    .contact-center {
        padding: 5px 0;
    }
}

@media (max-width: 480px) {
    .profile-img {
        width: 50%;
    }

    .team {
        padding: 0 2%;
    }

    .contact-title {
        font-size: calc(18px + 1vw);
    }

    .contact-center p, .contact-center h2 {
        font-size: calc(14px + 1vw);
    }
}
