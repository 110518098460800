.lato-font {
    font-family: 'Lato', sans-serif;
}

.roboto-mono-font {
    font-family: 'Roboto Mono', monospace;
}

.container {
    margin: 0 auto;
    background-color: #e1edf7;
}

.background-container {
    background-color: #e1edf7;
    overflow-x: clip;
}

/* BOX 1 */
.grid-b1 {
    display: grid;
    max-width: 2000px;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

}

.item1-b1 {
    padding: 20px 20px 0px;
}

.item2-b1 {
    padding: 20px;
}

.business-accounting {
    font-size: calc(15px + 0.390625vw);
    padding-left: 3.5%;
}

.beyond-balance-sheets {
    white-space: pre-wrap;
    font-size: calc((2) * 1.2vw + 1rem);
    margin-top: 0;
    padding-left: 3.5%;
}

.p-text {
    font-size: calc(12px + 0.390625vw);
    padding-left: 3.5%;
}

.desk-img {
    width: 100%;
    height: 100%;
    left: 25px;
}

/* BOX 2 */
.our-services-background {
    background-color: #243746;
    color: white;
    padding-bottom: 35px;
}

.our-services {
    padding-top: 2em;
    margin-top: 0;
    text-align: center;
    font-size: calc(15px + 0.390625vw);
}

.expert-accounting {
    text-align: center;
    font-size: calc(1 * 1.2vw + 1rem);
    padding-bottom: 1em;
}

.grid-b2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 3%;
}

.item1-b2 {
    background-color: #2f495e;
    border-radius: 35px;
    text-align: center;
}

.service-logo {
    border-radius: 50%;
    min-width: 20%;
    max-width: 25%;
    margin: 6% auto 0%;
    display: inline-block;
}

.service-subtitle {
    text-align: center;
    font-size: calc(.5 * 1.2vw + 1rem);
    margin-top: 4%;
    margin-bottom: 0;
}

.star {
    color: #243746;
    font-size: 30px;
    -webkit-text-stroke: 1px #243746;
    letter-spacing: 3px;
    margin: 0;
}

.service-description {
    text-align: center;
    font-size: calc(.1 * 1.2vw + 1rem);
    margin-top: 3%;
    padding: 0% 5% 4%;
}

.learn-more-btn {
    background-color: #2f495e;
    color: white;
    padding: 15px 32px;
    text-align: center;
    border-radius: 35px;
    margin: auto;
    display: block;
    max-width: 100px;
    font-size: calc(.01 * 1.2vw + 1rem);
    text-decoration: none;
}

.learn-more-btn:hover {
    text-decoration: underline;
}

/* BOX 3 */
.about-title {
    font-size: calc(1.5 * 1.2vw + 1rem);
    padding-left: 3.5%;
}

.business-img-b2 {
    width: 100%;
    height: 100%;
}

/* BOX 4 */
.grid-b4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    padding: 5%;
}

.item2-b4 {
    color: black;
    background-color: #e1edf7;
    border-radius: 35px;
}

/* BOX 5 */
.grid-b5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

/* Mobile-Friendly Styles */
@media (max-width: 768px) {
    .grid-b1, .grid-b2, .grid-b5, .grid-b4 {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .beyond-balance-sheets, .about-title, .business-accounting, .service-description, .our-services, .expert-accounting {
        text-align: center;
    }

    .desk-img, .business-img-b2, .service-logo {
        width: 100%;
    }

    .item1-b1, .item1-b2, .item2-b4 {
        padding: 10px;
    }

    .learn-more-btn {
        width: 80%;
    }

    .service-logo {
        width: 40%;
    }
}

@media (max-width: 425px) {
    .beyond-balance-sheets, .about-title, .our-services, .expert-accounting {
        font-size: 1.5rem;
    }

    .service-description {
        font-size: 1rem;
    }

    .learn-more-btn {
        font-size: 1rem;
        padding: 10px 20px;
        width: 100%;
    }

    .service-logo {
        width: 50%;
    }
}
