.homeContainer {
    background-color: #233746;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.whiteLogo {
    max-width: 500px;
    width: 100%;
    margin: -2px;
    padding: 0;
}

.whiteLogo img {
    width: 100%;
    height: auto;
}